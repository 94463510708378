import { LoginForm } from '../../components/Login/LoginForm';
import { Button, Stack } from '@mui/material';
import { useLoginTranslation } from '../../i18n/useTranslations';
import { useNavigate } from 'react-router-dom';
import { StyledPaper } from '../../components/UI/StyledPaper';

export default function LoginPage() {
  const { t } = useLoginTranslation();
  const navigate = useNavigate();
  return (
    <Stack flexGrow={1} direction="column" alignItems="center" justifyContent="center" spacing={2}>
      <StyledPaper elevation={0}>
        <Stack justifyContent="center" alignItems="center" width={300}>
          <LoginForm />
        </Stack>
      </StyledPaper>
      <Button
        variant="text"
        aria-label="Forgot your password?"
        sx={{
          textDecoration: 'underline',
          fontStyle: 'normal',
          color: 'var(--white)',
        }}
        onClick={() => navigate('/login/forget-password')}
      >
        {t('forgetPSWBtn')}
      </Button>
    </Stack>
  );
}
