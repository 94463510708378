import { Avatar, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { VideoLabelChip } from './VideoLabelChip';

//TRANSLATION
export const InfoSection = (props: { isLive: boolean; isStreamLoading: boolean; handleSwitchCamera: () => void }) => {
  const { isLive, isStreamLoading, handleSwitchCamera } = props;
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  const handleRedirectToStreamDetailPage = () => navigate(`/shows/${params.id}`);

  return (
    <Stack
      bgcolor={
        isLive && !isStreamLoading ? 'transparent' : theme.components?.pwaComponents?.infoSection?.backgroundColor
      }
      width="100%"
      height="40px"
      spacing={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        zIndex: 200,
        position: 'absolute',
        top: 0,
        left: 0,
        px: 0.5,
      }}
    >
      {isLive && !isStreamLoading ? (
        <VideoLabelChip />
      ) : (
        <IconButton onClick={handleRedirectToStreamDetailPage}>
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: theme.components?.pwaComponents?.infoSection?.button?.backgroundColor,
              width: '28px',
              height: '28px',
            }}
          >
            <Icon className="fa-light fa-arrow-left" sx={{ color: 'text.primary' }} />
          </Avatar>
        </IconButton>
      )}

      {!isLive && !isStreamLoading && (
        <Typography fontSize={14} color="text.primary" fontWeight={700}>
          Video preview mode
        </Typography>
      )}

      <IconButton onClick={handleSwitchCamera}>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: theme.components?.pwaComponents?.infoSection?.button?.backgroundColor,
            width: '28px',
            height: '28px',
            mt: isLive ? 1.25 : 'inherit',
          }}
        >
          <Icon className="fa-sharp fa-light fa-arrows-rotate" sx={{ color: 'text.primary' }} />
        </Avatar>
      </IconButton>
    </Stack>
  );
};
