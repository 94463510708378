import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.scss';
import { BrowserRouter } from 'react-router-dom';
import { DeviceMetadataProvider } from './components/Provider/DeviceMetadataProvider/DeviceMetadataProvider';
import { LanguageProvider } from './components/Provider/LanguageProvider';
import { ThemeRegistry } from './components/Provider/ThemeProvider';
import { QueryProvider } from './components/Provider/QueryProvider';
import { AxiosInterceptor } from './components/AxiosInterceptor';
import { LoadingSpinner } from './components/UI/Indicators/LoadingSpinner';
import { AuthProvider } from './components/Authentication/AuthProvider';
import { LicenseInfo } from '@mui/x-license-pro';

const MUI_PRO_LICENSE = process.env.REACT_APP_MUI_PRO_LICENSE || '';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <BrowserRouter>
        <QueryProvider>
          <DeviceMetadataProvider>
            <ThemeRegistry>
              <AxiosInterceptor>
                <Suspense fallback={<LoadingSpinner />}>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </Suspense>
              </AxiosInterceptor>
            </ThemeRegistry>
          </DeviceMetadataProvider>
        </QueryProvider>
      </BrowserRouter>
    </LanguageProvider>
  </React.StrictMode>,
);
