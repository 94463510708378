import { Divider, Icon, Stack, Typography } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { useGetAvailableShows } from '../../api/show/showApi';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export const useShowTable = () => {
  const { availableShowList: rows = [], isLoading } = useGetAvailableShows();

  const navigate = useNavigate();
  const handleRedirect = (url: string) => navigate(url);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Show',
      renderCell: (params) => {
        return (
          <Stack direction="column" alignItems="start" spacing={1}>
            <Typography fontSize={14} fontWeight={700}>
              {params.row.title}
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.8}>
              <Typography fontSize={13} color="text.primary">
                {dayjs(params.row.showtime).format('DD:MM:YYYY')}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography fontSize={13} color="text.primary">
                {dayjs(params.row.showtime).format('HH:mm')}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'status',
      headerName: 'State',
      renderCell: (params) => {
        return (
          <Stack
            width="100%"
            height="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                width: '1px',
                height: '100%',
                backgroundColor: 'var(--grey-25)',
              },
            }}
          >
            <Typography fontSize={13} color="text.primary" sx={{ pl: '10px' }}>
              {params.row.displayStatus}
            </Typography>
            <Icon className="fa-regular fa-chevron-right" sx={{ fontSize: '16px' }} />
          </Stack>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
  ];

  const onClickHandler = (props: GridCellParams) => handleRedirect(`/shows/${props.row.id}`);
  return { onClickHandler, columns, rows, isLoading };
};
