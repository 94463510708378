import { AmazonIVSBroadcastClient } from 'amazon-ivs-web-broadcast';
import { MutableRefObject, useCallback } from 'react';

export const useCamera = (videoElemRef: MutableRefObject<HTMLVideoElement | null>) => {
  const getCameraStream = useCallback(async (deviceId: string) => {
    let media;

    const videoConstraints = {
      deviceId: { exact: deviceId },
      aspectRatio: 16 / 9,
    };
    try {
      media = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
    } catch (e) {
      media = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
    }
    return media;
  }, []);

  // Removes a layer from the layer array and removes it from the canvas
  const removeVideoDevice = useCallback(
    async (
      client: null | AmazonIVSBroadcastClient,
      device: MediaDeviceInfo | null,
      handleError: (errMsg: string) => void,
    ) => {
      if (!device) return;
      try {
        const { deviceId } = device;
        const videoStream = client?.getVideoInputDevice(deviceId);
        if (videoStream) {
          for (const track of videoStream.source.getTracks()) {
            track.enabled = false;
            track.stop();
          }
          client?.removeVideoInputDevice(deviceId);
        }
        return;
      } catch (err) {
        handleError(err as string);
      }
    },
    [],
  );

  const addVideoDevice = useCallback(
    async (client: null | AmazonIVSBroadcastClient, device: MediaDeviceInfo, handleError: (errMsg: string) => void) => {
      try {
        await removeVideoDevice(client, device, handleError);
        const { deviceId } = device;
        const cameraStream = await getCameraStream(deviceId);
        await client?.addVideoInputDevice(cameraStream, deviceId, { index: 0 });
        if (videoElemRef.current) videoElemRef.current.srcObject = cameraStream;
      } catch (err) {
        handleError(err as string);
      }
    },
    [getCameraStream, removeVideoDevice, videoElemRef],
  );

  return { addVideoDevice, removeVideoDevice, getCameraStream };
};
