import { LoginRequestParams as UserLoginForm } from '../../api/user/userApi.models';
import { Icon, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { StyledTextField } from '../UI/StyledTextField';
import { useState } from 'react';
import { useLoginTranslation } from '../../i18n/useTranslations';

export const LoginPasswordController = (props: { control: Control<UserLoginForm>; error: boolean }) => {
  const { control, error } = props;
  const { t } = useLoginTranslation();
  const [pwdHidden, setHidden] = useState<boolean>(true);

  return (
    <Controller
      name="password"
      rules={{ required: true }}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => (
        <StyledTextField
          sx={{
            input: { color: error ? 'error.main' : 'inherit' },
            width: '100%',
            padding: 0,
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: fieldState.error || error ? 'error.main' : 'primary.main',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: fieldState.error || error ? 'error.main' : 'primary.main',
            },
          }}
          className="MuiInputBase"
          variant="outlined"
          placeholder={t('passwordFieldPlaceholder')}
          type={pwdHidden ? 'password' : 'text'}
          error={!!error}
          value={value}
          onChange={onChange}
          autoComplete="off"
          helperText={
            error && value.length ? (
              <Stack component="span" direction="row" alignItems="center" spacing={1} mt={1}>
                <Icon component="span" className="fa-light fa-circle-info" sx={{ fontSize: 22, color: 'error.main' }} />
                <Typography component="span" color="error" fontSize={14} noWrap>
                  Incorrect email or password.
                </Typography>
              </Stack>
            ) : (
              ''
            )
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon color={error ? 'error' : 'inherit'} fontSize="small" className="fa-light fa-lock-keyhole" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setHidden(!pwdHidden)} edge="end">
                  {pwdHidden ? (
                    <Icon
                      color={error ? 'error' : 'inherit'}
                      sx={{ width: '100%' }}
                      fontSize="small"
                      className="fa-light fa-eye-slash"
                    />
                  ) : (
                    <Icon
                      color={error ? 'error' : 'inherit'}
                      sx={{ width: '100%' }}
                      fontSize="small"
                      className="fa-light fa-eye"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
