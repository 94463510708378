import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider';

const ProtectedRoutingContainer = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};
export default ProtectedRoutingContainer;
