import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { CustomNoRowsOverlay } from './NoDataTableOverlay';
import { StyledDataGridWrapper } from '../../UI/StyledDataGridWrapper';
import { Stack, Typography } from '@mui/material';
import { Show, ShowStatus } from '../../../api/show/showApi.models';
import { getTimeDifference } from '../../../utils/useCountDown';
import { useEffect, useState } from 'react';

interface DataGridProps {
  loading: boolean;
  columns: GridColDef[];
  rows: GridValidRowModel[];
  cellClickHandler: (params: GridCellParams) => void;
}

const ROWS_PER_PAGE = 10;

const ColumnPropsDefaultFilter = (columns: GridColDef[]) => {
  return columns.map((m) => {
    m.width == null ? (m.flex = 1) : null;
    return m;
  });
};

const getDetailPanelContent = (show: Show) => {
  const timeDifference = getTimeDifference(show.showtime);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ bgcolor: 'var(--secondary-color--orange)', height: '100%' }}
    >
      <Typography fontSize={12} fontWeight={700} textAlign="center" color="common.white">
        {`Show starts in ${timeDifference} minutes`}
      </Typography>
    </Stack>
  );
};

const getDetailPanelHeight = () => 24;

export const StyledDataGrid = ({ columns, rows, loading, cellClickHandler }: DataGridProps) => {
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    if (rows.length === 0) return;

    setExpandedRowIds((prevIds) => {
      const uniqueExpandedIds = new Set<GridRowId>(prevIds);

      for (const show of rows) {
        const isStreaming = show?.status === ShowStatus.STREAMING || show?.status === ShowStatus.PAUSED;

        if (show.permissions?.manageable === true && !uniqueExpandedIds.has(show.id) && !isStreaming) {
          uniqueExpandedIds.add(show.id);
        }
      }
      return Array.from(uniqueExpandedIds);
    });
  }, [rows]);

  return (
    <StyledDataGridWrapper>
      <DataGridPro
        disableColumnResize
        autoHeight
        loading={loading}
        disableColumnFilter
        disableColumnMenu
        hideFooterSelectedRowCount
        rows={rows}
        getRowHeight={() => 'auto'}
        rowThreshold={0}
        detailPanelExpandedRowIds={expandedRowIds}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={({ row }) => getDetailPanelContent(row)}
        columns={ColumnPropsDefaultFilter(columns)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            },
          },
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        {...(rows?.length <= ROWS_PER_PAGE && {
          hideFooterPagination: true,
          hideFooter: true,
        })}
        {...(cellClickHandler !== undefined && { onCellClick: (params) => cellClickHandler(params) })}
      />
    </StyledDataGridWrapper>
  );
};
