import { CircularProgress, Stack } from '@mui/material';

export const LoadingSpinner = () => (
  <Stack
    sx={{
      width: '100%',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    }}
  >
    <CircularProgress />
  </Stack>
);
