import { Box, Icon, IconButton, ListItemIcon, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';
import { StyledMenu } from '../../components/UI/StyledMenu';
import { useTranslation } from 'react-i18next';
import { useUserLogout } from '../Authentication/methods';

const DROPDOWN_OPTIONS = [
  { name: 'Logout', code: null, icon: null },
  { name: 'English', code: 'en', icon: <Icon className="fa-light" baseClassName="fa-globe" /> },
  { name: 'Deutsch', code: 'de', icon: <Icon className="fa-light" baseClassName="fa-globe" /> },
  { name: 'Español', code: 'es', icon: <Icon className="fa-light" baseClassName="fa-globe" /> },
  { name: '繁體中文', code: 'zh', icon: <Icon className="fa-light" baseClassName="fa-globe" /> },
];

export const Layout = ({ children }: { children: ReactNode }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { i18n } = useTranslation();
  const { handleUserLogout } = useUserLogout();
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleMenuToggle = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleSelectedMenuItem = (_event: MouseEvent<HTMLElement>, index: number, code: string | null) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    code && switchLng(code);
    !code && handleUserLogout();
  };

  const switchLng = (lngCode: string) => i18n.changeLanguage(lngCode);

  return (
    <Stack sx={{ height: '100vh', maxHeight: '100vh', backgroundColor: theme.palette.grey[100] }}>
      <Stack width="100%" justifyContent="space-between" alignItems="center" direction="row" p={1.5}>
        <Box component="img" src="/LSLogo.svg" alt="LS Logo" width={200} height={20} />
        <IconButton size="small" onClick={handleMenuToggle}>
          {open ? (
            <Icon className="fa-light fa-xmark-large" sx={{ color: 'text.primary' }} />
          ) : (
            <Icon className="fa-light fa-bars-sort" sx={{ color: 'text.primary' }} />
          )}
        </IconButton>
      </Stack>
      {children}
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        id="user-dropdown-menu"
        aria-labelledby="user-dropdown-button"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {DROPDOWN_OPTIONS.map((option: { name: string; code: string | null }, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleSelectedMenuItem(event, index, option.code);
            }}
          >
            <ListItemIcon>
              {option.code ? (
                <Icon className="fa-light" baseClassName="fa-globe" color="secondary" />
              ) : (
                <Icon className="fa-light" baseClassName="fa-right-from-bracket" color="secondary" />
              )}
            </ListItemIcon>
            <Typography fontSize={14}>{option.name}</Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </Stack>
  );
};
