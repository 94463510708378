import * as userModel from '../../api/user/userApi.models';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import { callRefreshToken, callUserLogin, requestRecoverPwd } from '../../api/user/userApi';
import tokenService from '../../services/token.service';
import { RecoverPwdStage } from '../../pages/Auth/ForgetPassworPage';

export const useUserLogin = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const { mutate, error } = useMutation({
    mutationKey: ['userLogin'],
    mutationFn: ({ email, password }: userModel.LoginRequestParams) => callUserLogin({ email, password }),
    onSuccess: (res) => {
      setIsAuthenticated(true);
      tokenService.updateLocalAccessToken(res.data.data.authenticationDetails.accessToken);
      tokenService.updateRefreshToken(res.data.data.authenticationDetails.refreshToken);
      tokenService.updateLocalIdToken(res.data.data.authenticationDetails.idToken);
      navigate('/shows');
    },
    onError: () => setIsAuthenticated(false),
  });

  const handleUserLogin = ({ email, password }: userModel.LoginRequestParams) => mutate({ email, password });
  return { handleUserLogin, error };
};

export const useUserLogout = () => {
  const { setIsAuthenticated } = useAuth();

  const handleUserLogout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
  };

  return { handleUserLogout };
};

export const useRefreshToken = () => {
  const { mutate } = useMutation({
    mutationKey: ['refreshToken'],
    mutationFn: async () => await callRefreshToken(),
    onSuccess: (res) => {
      const { accessToken, idToken } = res.data.data.authenticationDetails;
      tokenService.updateLocalIdToken(idToken);
      tokenService.updateLocalAccessToken(accessToken);
    },
  });

  const handleRefreshToken = async () => mutate();

  return { handleRefreshToken };
};

export const useRecoverPwd = (setRecoverPwdStage: (stage: RecoverPwdStage) => void) => {
  const { mutate } = useMutation({
    mutationKey: ['recoverpassword'],
    mutationFn: async (email: string) => await requestRecoverPwd(email),
    onSuccess: () => setRecoverPwdStage(RecoverPwdStage.RecoverPwdSuccess),
  });

  const handleRequestRecoverPwd = async (email: string) => mutate(email);

  return { handleRequestRecoverPwd };
};
