import { Stack } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider';
import { useDeviceMetadata } from '../Provider/DeviceMetadataProvider/DeviceMetadataProvider';
const LOGIN_BACKGROUND = 'linear-gradient(var(--primary-color), var(--secondary-color--blue))';

const PublicRoutingContainer = () => {
  const { isAuthenticated } = useAuth();
  const { isMobileView } = useDeviceMetadata();

  if (!isAuthenticated) {
    return (
      <Stack
        sx={{
          height: '100vh',
          maxHeight: '100vh',
          background: LOGIN_BACKGROUND,
          overflow: isMobileView ? 'hidden' : 'auto',
        }}
      >
        <Outlet />;
      </Stack>
    );
  } else {
    return <Navigate to="/shows" />;
  }
};
export default PublicRoutingContainer;
