import tokenService from '../../services/token.service';
import { useRefreshToken, useUserLogout } from '../Authentication/methods';
import { ReactNode, useCallback, useEffect } from 'react';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

const AxiosInstance = axios.create();

export const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const { handleRefreshToken } = useRefreshToken();
  const { handleUserLogout } = useUserLogout();

  const requestAuthHandler = useCallback((config: InternalAxiosRequestConfig) => {
    const accessToken = tokenService.getLocalAccessToken();
    if (accessToken) {
      config.headers = config.headers || {};
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  }, []);

  const errRefreshTokenHandler = useCallback(
    async (error: AxiosError) => {
      const originalRequest = error.config;

      if (originalRequest && error.response?.status === 401) {
        try {
          await handleRefreshToken();
          return AxiosInstance(originalRequest);
        } catch (_error) {
          handleUserLogout();
        }
      } else {
        return Promise.reject(error);
      }
    },
    [handleRefreshToken, handleUserLogout],
  );

  const requestAuthInterceptor = AxiosInstance.interceptors.request.use(requestAuthHandler);
  const respInterceptor = AxiosInstance.interceptors.response.use((resp) => resp, errRefreshTokenHandler);

  useEffect(() => {
    return () => AxiosInstance.interceptors.request.eject(requestAuthInterceptor);
  }, [requestAuthInterceptor]);

  useEffect(() => {
    return () => AxiosInstance.interceptors.response.eject(respInterceptor);
  }, [respInterceptor]);

  return <>{children}</>;
};

export default AxiosInstance;
