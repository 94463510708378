import { Box, styled } from '@mui/material';

export const StyledDataGridWrapper = styled(Box)`
  width: 100%;
  .MuiDataGrid-root {
    margin: 16px;
    border: 1px solid;
    border-color: var(--primary-color--dark);
    font-family: Inter;
    & .MuiDataGrid-main {
      border-radius: 4px;
      & .MuiDataGrid-columnHeaders {
        background-color: var(--primary-color--dark);
        color: var(--white);
        border-radius: 0;
        .MuiDataGrid-columnHeader {
          outline: none;
          padding: 0 20px;
          &TitleContainer {
            flex-direction: row;
          }
          & .MuiIconButton-root {
            color: var(--white);
            &:focus {
              outline: none;
            }
          }
        }
        .MuiDataGrid-columnHeaderTitle {
          font-weight: 700;
          font-size: 18px;
        }
      }
      & .MuiDataGrid-columnSeparator {
        visibility: hidden;
      }
      & .MuiDataGrid-row {
        background-color: var(--grey-10);
        &:hover {
          cursor: pointer;
        }
        &:hover,
        &.Mui-selected {
          background-color: var(--primary-color--light) !important;
        }
        & .MuiDataGrid-cell {
          font-size: 16px;
          border-bottom: none;
          border-top: 2px solid var(--white);
          padding: 13px 20px;
          text-align: left;
          &--textRight {
            justify-content: center;
          }
          &:first-of-type {
            color: var(--text-color);
            font-weight: 600;
          }
          &:focus,
          &:focus-within {
            outline: none;
          }
          &[data-field='actions'] {
            button {
              transition: none;
              &:hover {
                background-color: var(--dark-color--blue-grey);
                color: var(--white);
                border-radius: 8px;
              }
              &.selected {
                background-color: var(--dark-color--blue-grey);
                color: var(--white);
                border-radius: 8px;
                transition: none;
              }
              &:focus,
              &:focus-visible {
                outline: none;
              }
            }
          }
        }
      }
    }
    .MuiDataGrid-footerContainer {
      justify-content: center;
      border-top: none;
      padding-top: 35px;
      padding-bottom: 35px;
      & .MuiPaginationItem-root {
        color: var(--text-color);
        font-size: 16px;
        min-width: 40px;
        height: 40px;
        border-radius: 20px;
        &.Mui-selected {
          background-color: var(--grey-20);
        }
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
`;
