import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { ChannelServiceEndpoint } from '../api.setting';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import AxiosInstance from '../../components/AxiosInterceptor';

enum ChatEventName {
  NotifyEndShow = 'NOTIFY_END_SHOW',
}

interface ChatEvent {
  attributes: { message: string };
  eventName: ChatEventName;
  roomIdentifier: string;
}

export const callGetAvailableShows = () => {
  const config: AxiosRequestConfig = {
    method: 'get',
    baseURL: ChannelServiceEndpoint,
    url: '/host/shows/availableList',
  };
  return AxiosInstance<AxiosResponse>(config);
};

export const useGetAvailableShows = () => {
  const { data: availableShowList, isLoading } = useSuspenseQuery({
    queryKey: ['availableShowList'],
    queryFn: async () =>
      await callGetAvailableShows()
        .then((res) => res.data.data)
        .catch(() => []),
  });
  return { availableShowList, isLoading };
};

export const callGetShowDetails = (showId: number) => {
  const config: AxiosRequestConfig = {
    method: 'get',
    baseURL: ChannelServiceEndpoint,
    url: `/host/show/${showId}`,
  };
  return AxiosInstance<AxiosResponse>(config);
};

export const useGetShowDetails = (showId: number) => {
  const { data: showDetails, isLoading } = useSuspenseQuery({
    queryKey: ['showDetails', showId],
    queryFn: async () =>
      await callGetShowDetails(showId)
        .then((res) => res.data.data)
        .catch(() => null),
  });
  return { showDetails, isLoading };
};

export const callChatSendEvent = async (event: ChatEvent) => {
  const path = `${ChannelServiceEndpoint}/chatevent`;
  return AxiosInstance.post(path, event);
};

export const useEndShow = (roomIdentifier: string) => {
  const callPutEndShow = (showId: number) => {
    const config: AxiosRequestConfig = {
      method: 'put',
      baseURL: ChannelServiceEndpoint,
      url: `/host/show/ended/${showId}`,
    };
    return AxiosInstance<AxiosResponse>(config);
  };

  const endShowChatEvent: ChatEvent = {
    attributes: { message: '' },
    eventName: ChatEventName.NotifyEndShow,
    roomIdentifier: roomIdentifier,
  };

  const { mutate, isSuccess } = useMutation({
    mutationKey: ['endShow'],
    mutationFn: (showId: number) => callPutEndShow(showId),
    onSuccess: () => callChatSendEvent(endShowChatEvent),
  });

  const endShow = async (showId: number) => mutate(showId);

  return { endShow, isSuccess };
};
