import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import themeOptions from './themeOptions';
import defaultTheme from './theme-default.json';

export const ThemeRegistry = ({ children }: { children: React.ReactNode }) => {
  const customTheme = createTheme({ ...themeOptions, ...defaultTheme.theme });

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
