import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenu = styled(Menu)({
  '.MuiList-root': {
    padding: 0,
  },

  '.MuiPopover-paper': {
    borderRadius: '4px',
    boxShadow: 'none',
    border: '1px solid #EAEAEA',
  },

  '.MuiButtonBase-root': {
    width: '170px',

    '&:hover': {
      backgroundColor: 'var(--grey-20)',
    },

    '&.Mui-selected': {
      backgroundColor: 'var(--secondary-color--orange)',
      '&:hover': {
        backgroundColor: 'var(--grey-20)',
      },
    },
  },
});
