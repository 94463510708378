import { Divider, Icon, IconButton, Stack, Typography } from '@mui/material';
import { CheckList } from '../../components/ShowDetail/CheckList';
import { StyledButton } from '../../components/UI/StyledButton';
import { ShowStatus } from '../../api/show/showApi.models';
import { Layout } from '../../components/Layout/Layout';
import { LoadingSpinner } from '../../components/UI/Indicators/LoadingSpinner';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useShowsTranslation } from '../../i18n/useTranslations';
import { useGetShowDetails } from '../../api/show/showApi';
import { useCountDown } from '../../utils/useCountDown';
import dayjs from 'dayjs';

//TRANSLATION
function ShowDetailPage() {
  const navigate = useNavigate();
  const params = useParams();
  const showId = parseInt(params.id as string);

  const handleRedirect = () => navigate('/shows');
  const handleRedirectToBraodcastPage = () => navigate(`/shows/${params.id}/broadcast`);

  const { showDetails, isLoading } = useGetShowDetails(showId);
  const isStreaming = showDetails?.status === ShowStatus.STREAMING || showDetails?.status === ShowStatus.PAUSED;

  const { timeDiff } = useCountDown(showDetails?.showtime, isStreaming);
  const { t } = useShowsTranslation();

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!showDetails) {
    return <Navigate to="/shows" />;
  } else {
    return (
      <Layout>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: 'common.white', borderRadius: '16px', flexGrow: 1, mt: 3.75 }}
        >
          <Stack width="100%" height="100%" direction="column" spacing={3} p={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton size="small" sx={{ pl: 0 }} onClick={handleRedirect}>
                <Icon className="fa-light" baseClassName="fa-arrow-left" sx={{ color: 'text.primary' }} />
              </IconButton>
              <Typography fontSize={20} fontWeight={700} color="text.primary">
                {showDetails?.title}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize={16} fontWeight={700} color="text.primary">
                {t('showDetails.dateAndTime')}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Stack direction="row" justifyContent="start" alignItems="center" spacing={0.8}>
                <Typography fontSize={16} color="secondary.dark">
                  {dayjs(showDetails?.showtime).format('DD:MM:YYYY')}
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography fontSize={16} color="text.primary">
                  {dayjs(showDetails?.showtime).format('HH:mm')}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography fontSize={16} fontWeight={700} color="text.primary">
                Show description
              </Typography>
              <Divider sx={{ mb: 2 }} />

              <Typography fontSize={14} color="text.primary">
                {showDetails?.description}
              </Typography>
            </Stack>
            <StyledButton
              sx={{ alignSelf: 'center' }}
              variant="contained"
              color="secondary"
              aria-label="Login"
              disableElevation
              onClick={handleRedirectToBraodcastPage}
              disabled={!showDetails?.permissions?.manageable || !showDetails}
              type="submit"
            >
              {isStreaming ? 'Resume' : 'Start'}
            </StyledButton>
            {!isStreaming ? (
              showDetails?.permissions?.manageable ? (
                <Typography fontSize={14} fontWeight={700} color="secondary.dark">
                  {`Show is schedueled to start in ${timeDiff} minutes.`}
                </Typography>
              ) : (
                <Typography fontSize={14} fontWeight={400} color="text.primary">
                  The video preview will be available 25 minutes before show time.
                </Typography>
              )
            ) : null}
            <CheckList />
          </Stack>
        </Stack>
      </Layout>
    );
  }
}

export default ShowDetailPage;
