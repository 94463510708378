import { Icon, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

const CHECK_LIST = [
  "Activate 'Do Not Disturb' Mode",
  'Check Internet Connectivity',
  'Test Audio and Video Quality',
  'If Bluetooth devices will be used, check the connectivity',
  'Confirm Lighting Setup',
  'Charge Your Device',
];

export const CheckList = () => {
  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon className="fa-sharp fa-light fa-circle-info" sx={{ overflow: 'visible' }} />
        <Typography fontSize={14} fontWeight={700} color="text.primary">
          Please make sure everything is ready before starting the show:
        </Typography>
      </Stack>
      <List sx={{ listStyleType: 'disc', ml: 6, maxWidth: '250px' }}>
        {CHECK_LIST.map((item, index) => (
          <ListItem disablePadding key={index} sx={{ display: 'list-item' }}>
            <ListItemText primary={item} sx={{ '& .MuiTypography-root': { fontSize: '14px', display: 'inline' } }} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
