import styled from '@emotion/styled';
import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';

const TabSelectionWrapper = styled(Box)`
  .MuiTabs-root {
    min-height: 32px;
  }

  .MuiButtonBase-root {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-transform: none;
    min-height: 32px;
    padding: 4px 24px;
    color: var(--text-color);
    border-bottom: 1px solid var(--grey-20);

    &.Mui-selected {
      color: var(--primary-color--dark);
      font-weight: 700;
    }

    &:focus {
      outline: none;
    }
  }
`;

export interface TabOptions {
  label: string;
  value: string;
}

interface TabProps {
  options: TabOptions[];
  selectedTabValue: string;
  disabled: boolean;
  onValueChange: (_: SyntheticEvent, newValue: string) => void;
}

export const TabSelection = ({ options, selectedTabValue, disabled, onValueChange }: TabProps) => {
  return (
    <TabSelectionWrapper sx={{ marginBottom: '8px' }}>
      <Tabs
        centered
        value={selectedTabValue}
        onChange={onValueChange}
        aria-label="filter tab"
        TabIndicatorProps={{ style: { backgroundColor: 'primary.dark' } }}
      >
        {options.map((option) => (
          <Tab disabled={disabled} key={option.label} label={option.label} value={option.value} disableRipple />
        ))}
      </Tabs>
    </TabSelectionWrapper>
  );
};
