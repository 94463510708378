import { createContext, useContext, useState } from 'react';
import { getUserLoggedInStatus } from './helpers';
interface AuthContextData {
  readonly isAuthenticated: boolean;
  readonly setIsAuthenticated: (isLoggedIn: boolean) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(getUserLoggedInStatus());
  const value: AuthContextData = {
    isAuthenticated,
    setIsAuthenticated,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextData => useContext(AuthContext);
