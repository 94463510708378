export enum ShowStatus {
  STREAMING = 'streaming',
  ENDED = 'ended',
  READY = 'ready',
  CANCEL = 'cancel',
  PAUSED = 'paused',
}

export enum ShowDisplayStatus {
  Cancelled = 'Cancelled',
  Upcoming = 'Upcoming',
  Live = 'Live',
  Ended = 'Ended',
}

export enum ProductType {
  Product = 'product',
  NonProduct = 'nonProduct',
}

export enum InputFileType {
  External = 'EXTERNAL',
  Uploaded = 'UPLOADED',
}

export interface Product {
  readonly brand?: string;
  readonly description?: string;
  readonly gtin: string;
  readonly id: number;
  readonly image: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly itemid: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly order: any;
  readonly price?: number;
  readonly priceCurrency?: string;
  readonly productid: string;
  readonly title: string;
  readonly url: string;
  readonly type: ProductType;
  readonly sku: string;
  readonly highlightTime?: number | null;
}

export interface ShowPermission {
  editable: boolean;
  deleteable: boolean;
  manageable: boolean;
  viewable: boolean;
}

export interface HighlightedItems {
  type: ProductType;
  id: number;
}

export interface Tag {
  readonly id?: number;
  readonly name: string;
}

export interface Show {
  channelArn: string;
  channelType: string;
  chatroomArn: string;
  companyId: number;
  createDate: string;
  creatorUserId: number;
  description: string;
  uuid: string;
  id: number;
  ingestionServer: string;
  latencyMode: string;
  modifyDate: string;
  modifyUserId: number;
  name: string;
  playbackUrl: string;
  showtime: string;
  status: ShowStatus;
  displayStatus: ShowDisplayStatus;
  streamingKey: string;
  streamingKeyArn: string;
  timeEnded: string;
  timeStarted: string;
  title: string;
  hosts: number[];
  displayMinimized: boolean;
  displayVodBotAssistant: boolean;
  displayFab: boolean;
  showPublishedItems: Product[];
  permissions: ShowPermission;
  codeSnippet: string;
  fabCodeSnippet: string;
  highlightedItems: HighlightedItems[];
  beforeShowImageUrl: string;
  beforeShowImageType: InputFileType;
  beforeShowImageName: string;
  beforeShowImageSize: number;
  pausedShowImageUrl: string;
  pausedShowImageType: InputFileType;
  pausedShowImageName: string;
  pausedShowImageSize: number;
  afterShowImageUrl: string;
  afterShowImageType: InputFileType;
  afterShowImageName: string;
  afterShowImageSize: number;
  tags: Tag[];
  includedInMediaLibraries: boolean;
  displayEndShowCta: boolean;
  endShowCtaUrl: string;
  endShowCtaText: string;
  chatLogsContent: string;
}
