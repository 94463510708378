import { Stack, Typography, useTheme } from '@mui/material';
import { ShowStatus } from '../../api/show/showApi.models';
import { useCountDown } from '../../utils/useCountDown';
import { StyledButton } from '../UI/StyledButton';

export const ControlSection = (props: {
  handleStartStream: () => void;
  showTime: string | null;
  isLive: boolean;
  manageable: boolean;
  status: ShowStatus;
  isLoading: boolean;
}) => {
  const { handleStartStream, manageable, showTime, isLive, isLoading, status } = props;
  const { isTimeUp, timeDiff } = useCountDown(showTime, isLive);
  const theme = useTheme();
  const isStreaming = status === ShowStatus.STREAMING || status === ShowStatus.PAUSED;

  return (
    <Stack
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 200,
      }}
      bgcolor={theme.components?.pwaComponents?.infoSection?.button?.backgroundColor}
      height="110px"
      width="100%"
      spacing={1}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledButton
        color="secondary"
        variant="contained"
        onClick={handleStartStream}
        disabled={manageable || isLoading}
      >
        {isLive ? 'End' : status === ShowStatus.PAUSED ? 'Resume' : 'Start'}
      </StyledButton>
      {!isTimeUp && !!timeDiff && !isStreaming && !isLive && (
        <Typography fontSize={14} color="text.primary" fontWeight={700} maxWidth="230px" textAlign="center">
          {`The live streaming will be available in ${timeDiff} minutes`}
        </Typography>
      )}
    </Stack>
  );
};
