import { AmazonIVSBroadcastClient } from 'amazon-ivs-web-broadcast';
import { useCallback, useState } from 'react';

export const useStream = () => {
  const [isLive, setIsLive] = useState(false);
  const [isStreamLoading, setStreamLoading] = useState(false);

  const startStream = useCallback(
    async (streamKey: string, client: null | AmazonIVSBroadcastClient, handleError: (errMsg: string) => void) => {
      let timer;
      if (!client) return;
      try {
        // Set a timeout on the stream to show an alert if the stream is taking longer than intended to start.
        timer = setTimeout(() => {
          handleError(
            "It's taking longer than ususal to start the stream. Check the status of your internet connection and disable any active VPNs.",
          );
          setIsLive(false);
          setStreamLoading(false);
        }, 10000);

        setStreamLoading(true);

        await client.startBroadcast(streamKey).finally(() => {
          setStreamLoading(false);
          setIsLive(true);
        });
        clearTimeout(timer);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (err.code) {
          case 10000:
            handleError(
              `Error starting stream: Your stream settings are misconfigured. This is likely caused by mismatched channel types. You must match the channel type of your Amazon IVS Channel.`,
            );
            break;
          default:
            handleError(`Error starting stream: ${err.message}.`);
            break;
        }
        if (timer) clearTimeout(timer);
        setIsLive(false);
        setStreamLoading(false);
      }
    },
    [],
  );

  const stopStream = useCallback(
    async (client: null | AmazonIVSBroadcastClient, handleError: (errMsg: string) => void) => {
      try {
        client?.disableAudio();
        client?.disableVideo();
        client?.stopBroadcast();
        client?.getAudioContext().close();
        setIsLive(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        handleError(`${err.name}: ${err.message}`);
      }
    },
    [],
  );

  return {
    isLive,
    isStreamLoading,
    setIsLive,
    startStream,
    stopStream,
  };
};
