import * as userModel from './userApi.models';
import { parseJwtToken } from '../../utils/parseJwtToken';
import { UserServiceEndpoint } from '../api.setting';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import tokenService from '../../services/token.service';

const getUserEmail = (): string => {
  const oriIdToken = tokenService.getLocalIdToken() as string;
  const idJwtObj = parseJwtToken(oriIdToken) as userModel.IdToken;
  return idJwtObj.email;
};

export const callUserLogin = async (requestBody: userModel.LoginRequestParams) => {
  const AxiosLoginInstance = axios.create();
  const userLoginConfig: AxiosRequestConfig = {
    method: 'post',
    baseURL: UserServiceEndpoint,
    url: '/login',
    data: requestBody,
  };
  return await AxiosLoginInstance<AxiosResponse>(userLoginConfig);
};

export const callRefreshToken = async () => {
  const AxiosRefreshTokenInstance = axios.create();
  const refreshTokenConfig: AxiosRequestConfig = {
    method: 'post',
    baseURL: UserServiceEndpoint,
    url: '/token/refresh',
    data: {
      refreshToken: tokenService.getLocalRefreshToken(),
      email: getUserEmail(),
    },
  };
  return await AxiosRefreshTokenInstance<AxiosResponse>(refreshTokenConfig);
};

export const requestRecoverPwd = async (email: string) => {
  const AxiosRecoverPWDInstance = axios.create();
  const requestRecoverPwdConfig: AxiosRequestConfig = {
    method: 'post',
    baseURL: UserServiceEndpoint,
    url: '/password/recover',
    data: { email },
  };
  return await AxiosRecoverPWDInstance(requestRecoverPwdConfig);
};
