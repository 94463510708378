import { RefObject } from 'react';

export const StreamPreview = (props: { videoElemRef: RefObject<HTMLVideoElement> }) => {
  const { videoElemRef } = props;
  return (
    <video
      style={{
        backgroundColor: 'black',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        position: 'absolute',
        inset: 0,
      }}
      ref={videoElemRef}
      muted
      autoPlay={true}
      playsInline={true}
    />
  );
};
