import { CircularProgress, Stack } from '@mui/material';

export const BroadcastLoadingSpinner = () => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'absolute',
    }}
  >
    <CircularProgress
      sx={{
        fontSize: '120px',
        color: 'common.white',
      }}
    />
  </Stack>
);
