import { Button, styled } from '@mui/material';

export const StyledButton = styled(Button)({
  lineHeight: '1.375rem',
  fontWeight: 700,
  fontSize: '1rem',
  padding: '8px 32px',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
});
