import { Box, Stack, Typography } from '@mui/material';
import { StyledButton } from '../UI/StyledButton';
import { LoginPasswordController } from './LoginPasswordController';
import { LoginEmailController } from './LoginEmailController';
import { LoginRequestParams as UserLoginForm } from '../../api/user/userApi.models';
import { useUserLogin } from '../Authentication/methods';
import { useLoginTranslation } from '../..//i18n/useTranslations';
import { useForm } from 'react-hook-form';

const DEFAULT_FORM_VALUE: UserLoginForm = {
  email: '',
  password: '',
};

export const LoginForm = () => {
  const { t } = useLoginTranslation();

  const userLoginForm = useForm<UserLoginForm>({
    mode: 'onSubmit',
    defaultValues: DEFAULT_FORM_VALUE,
  });

  const { control, handleSubmit, getValues } = userLoginForm;
  const { handleUserLogin, error } = useUserLogin();

  const submitUserLoginForm = () => {
    const { email, password } = getValues();
    handleUserLogin({ email, password });
  };

  return (
    <Stack justifyContent="center" alignItems="center" spacing={4.375} my={6.25}>
      <Box component="img" src="/LSLogo.svg" alt="LS Logo" width={200} height={20} />
      <Box>
        <Typography
          sx={{
            fontWeight: 900,
            color: 'var(--primary-color)',
          }}
          variant="h6"
        >
          {t('welcome')}
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit(submitUserLoginForm)}>
        <Stack justifyContent="center" alignItems="center" spacing={2} width={252}>
          <Stack spacing={1}>
            <LoginEmailController control={control} error={Boolean(error)} />
            <LoginPasswordController control={control} error={Boolean(error)} />
          </Stack>
          <StyledButton variant="contained" color="secondary" aria-label="Login" disableElevation type="submit">
            {t('loginBtn')}
          </StyledButton>
        </Stack>
      </Box>
    </Stack>
  );
};
