import { useCallback, useEffect, useRef, useState } from 'react';
import { useStream } from './useStream';
import { useEndShow, useGetShowDetails } from '../../api/show/showApi';
import { useParams } from 'react-router-dom';
import { useMixer } from './useMixer';
import { useCamera } from './useCamera';
import { getAudioDevices, getVideoDevices } from './Helpers/BroadcasSDKHelpers';

import IVSBroadcastClient, {
  AmazonIVSBroadcastClient,
  BASIC_PORTRAIT,
  BroadcastClientEvents,
  ConnectionState,
  LOG_LEVEL,
  STANDARD_PORTRAIT,
} from 'amazon-ivs-web-broadcast';

export const useBroadcastSDK = () => {
  const params = useParams();
  const client = useRef<null | AmazonIVSBroadcastClient>(null);
  const videoElemRef = useRef<HTMLVideoElement | null>(null);
  const selectedAudioDevice = useRef<null | MediaDeviceInfo>(null);
  const selectedVideoDevice = useRef<null | MediaDeviceInfo>(null);
  const videoDeviceListRef = useRef<MediaDeviceInfo[] | null>(null);
  const audioDeviceListRef = useRef<MediaDeviceInfo[] | null>(null);
  const isVideoStreamed = useRef(false);
  const showId = parseInt(params.id as string);
  const { showDetails } = useGetShowDetails(showId);
  const { addMixerDevice, removeMixerDevice } = useMixer();
  const { addVideoDevice, removeVideoDevice } = useCamera(videoElemRef);
  const { isLive, isStreamLoading, stopStream, startStream, setIsLive } = useStream();
  const { endShow, isSuccess } = useEndShow(showDetails.chatroomArn || '');
  const ingestServer = showDetails?.ingestionServer;
  const streamingKey = showDetails?.streamingKey;
  const showTime = showDetails?.showtime;
  const manageable = showDetails?.permissions?.manageable;
  const status = showDetails?.status;

  const [isDevicePermissionsDialogOpen, setDevicePermissionsDialogOpen] = useState(false);
  const [isEndShowDialogOpen, setEndShowDialogOpen] = useState(false);
  const [isEndShowSucessDialogOpen, setEndShowSucessDialogOpen] = useState(false);
  const [isConnectionErrorDialogOpen, setConnectionErroDialogOpen] = useState(false);
  const [isDeviceLoading, setDeviceLoading] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');

  const handleDevicePermissionDialogOpen = () => setDevicePermissionsDialogOpen(true);
  const handleDevicePermissionDialogClose = () => setDevicePermissionsDialogOpen(false);
  const handleEndShowDialogClose = () => setEndShowDialogOpen(false);
  const handleEndShowSucessialogOpen = () => setEndShowSucessDialogOpen(true);
  const handleEndShowSucessDialogClose = () => setEndShowSucessDialogOpen(false);
  const handleConnectionErrorDialogOpen = () => setConnectionErroDialogOpen(true);
  const handleConnectionErrorDialogClose = () => setConnectionErroDialogOpen(false);

  const attachBroadcastClientListeners = useCallback(() => {
    client.current?.on(BroadcastClientEvents.CONNECTION_STATE_CHANGE, handleConnectionStateChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detachBroadcastClientListeners = useCallback(() => {
    client.current?.off(BroadcastClientEvents.CONNECTION_STATE_CHANGE, handleConnectionStateChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteClient = useCallback(() => {
    setIsLive(false);
    detachBroadcastClientListeners();
    if (client.current) client.current.delete();
    if (videoElemRef.current) videoElemRef.current.srcObject = null;
    client.current = null;
    selectedVideoDevice.current = null;
    selectedAudioDevice.current = null;
    videoDeviceListRef.current = null;
    audioDeviceListRef.current = null;
  }, [detachBroadcastClientListeners, setIsLive]);

  const createClient = useCallback(() => {
    client.current = IVSBroadcastClient.create({
      streamConfig: process.env.REACT_APP_ENV === 'dev' ? BASIC_PORTRAIT : STANDARD_PORTRAIT,
      ingestEndpoint: ingestServer,
      logLevel: LOG_LEVEL.DEBUG,
    });
    attachBroadcastClientListeners();

    return client.current;
  }, [attachBroadcastClientListeners, ingestServer]);

  const handleError = useCallback((message: string) => {
    setErrorAlertMessage(`${message}`);
  }, []);

  const handleStartStream = useCallback(async () => {
    if (streamingKey && !isLive && client.current) {
      await startStream(streamingKey, client.current, handleError);
    } else if (isLive) {
      setEndShowDialogOpen(true);
    }
  }, [handleError, isLive, startStream, streamingKey]);

  const handleStopStream = useCallback(async () => {
    await Promise.all([endShow(parseInt(params.id as string)), stopStream(client.current, handleError)]);
    await Promise.all([
      removeVideoDevice(client.current, selectedVideoDevice.current, handleError),
      removeMixerDevice(client.current, selectedAudioDevice.current, handleError),
    ]);
  }, [endShow, handleError, params.id, removeVideoDevice, removeMixerDevice, stopStream]);

  const handleRetrieveDevices = useCallback(
    async (client: null | AmazonIVSBroadcastClient) => {
      try {
        setDeviceLoading(true);
        const videoDevices = await getVideoDevices({ handleError, deviceRef: videoDeviceListRef });
        const audioDevices = await getAudioDevices({ handleError, deviceRef: audioDeviceListRef });

        if (videoDevices && audioDevices) {
          selectedVideoDevice.current = videoDevices[0];
          selectedAudioDevice.current = audioDevices[0];
          await addVideoDevice(client, selectedVideoDevice.current, handleError);
          await addMixerDevice(client, selectedAudioDevice.current, handleError);
          await client?.getAudioContext().resume();
        }
      } catch (error) {
        handleError('Error getting devices');
      } finally {
        setDeviceLoading(false);
      }
    },
    [addMixerDevice, addVideoDevice, handleError],
  );

  const handlePermissions = useCallback(async () => {
    setDeviceLoading(true);
    handleDevicePermissionDialogClose();

    if (selectedVideoDevice.current && selectedAudioDevice.current && client.current) {
      await removeMixerDevice(client.current, selectedAudioDevice.current, handleError);
      await removeVideoDevice(client.current, selectedVideoDevice.current, handleError);
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

      for (const track of stream.getTracks()) {
        track.stop();
      }
      const videoDevices = await getVideoDevices({ handleError, deviceRef: videoDeviceListRef });
      const audioDevices = await getAudioDevices({ handleError, deviceRef: audioDeviceListRef });
      if (videoDevices && audioDevices) {
        selectedVideoDevice.current = videoDevices[0];
        selectedAudioDevice.current = audioDevices[0];

        await addVideoDevice(client.current, videoDevices[0], handleError);
        await addMixerDevice(client.current, audioDevices[0], handleError);
        await client.current?.getAudioContext().resume();
      }
    } catch (error) {
      handleError('Error getting devices');
    } finally {
      setDeviceLoading(false);
    }
  }, [addMixerDevice, addVideoDevice, handleError, removeMixerDevice, removeVideoDevice]);

  const handleSwitchCamera = useCallback(async () => {
    if (!videoElemRef.current || !videoDeviceListRef.current || videoDeviceListRef.current?.length === 0) return;
    setDeviceLoading(true);

    const primaryCamery = videoDeviceListRef.current[0];
    const secondaryCamera = videoDeviceListRef.current[1];
    if (!primaryCamery || !secondaryCamera) return;

    videoElemRef.current.srcObject = null;

    try {
      if (client.current?.getVideoInputDevice(primaryCamery.deviceId)) {
        await removeVideoDevice(client.current, primaryCamery, handleError);
        await addVideoDevice(client.current, secondaryCamera, handleError);
      } else {
        await removeVideoDevice(client.current, secondaryCamera, handleError);
        await addVideoDevice(client.current, primaryCamery, handleError);
      }
    } catch (error) {
      handleError('Error switching camera');
    } finally {
      setDeviceLoading(false);
    }
  }, [addVideoDevice, handleError, removeVideoDevice]);

  const handleResumeStream = useCallback(
    async (client: null | AmazonIVSBroadcastClient) => {
      try {
        await startStream(streamingKey, client, handleError);
        setDeviceLoading(false);
      } catch (error) {
        handleError('Connection Failed');
        setDeviceLoading(false);
      }
    },
    [handleError, startStream, streamingKey],
  );

  const handleRestart = useCallback(async () => {
    deleteClient();
    const newClient = createClient();
    if (newClient) {
      await handleRetrieveDevices(newClient);
      await handleResumeStream(newClient);
    }
  }, [createClient, deleteClient, handleResumeStream, handleRetrieveDevices]);

  const handleConnectionStateChange = useCallback(async () => {
    const state = client.current?.getConnectionState();
    switch (state) {
      case ConnectionState.CLOSED:
        await handleRestart();
        break;
      case ConnectionState.DISCONNECTED:
        await handleRestart();
        break;
      case ConnectionState.FAILED:
        await handleRestart();
        break;
      case ConnectionState.NONE:
        isVideoStreamed.current && setConnectionErroDialogOpen(true);
        break;
      case ConnectionState.CONNECTED:
        isVideoStreamed.current = true;
        break;
      default:
        break;
    }
  }, [handleRestart]);

  useEffect(() => {
    const checkConnectionState = async () => {
      if (document.visibilityState === 'visible' && selectedVideoDevice.current) {
        isVideoStreamed.current && (await handleConnectionStateChange());
      }

      if (document.visibilityState === 'hidden' && isVideoStreamed.current) {
        setIsLive(false);
        await stopStream(client.current, handleError);
      }
    };

    window.addEventListener('visibilitychange', checkConnectionState);
    return () => window.removeEventListener('visibilitychange', checkConnectionState);
  }, [handleConnectionStateChange, stopStream, handleError, setIsLive]);

  useEffect(() => {
    if (isSuccess) {
      handleEndShowDialogClose();
      handleEndShowSucessialogOpen();
    }
  }, [isSuccess]);

  useEffect(() => {
    createClient();
    return () => deleteClient();
  }, [createClient, deleteClient]);

  useEffect(() => {
    !isVideoStreamed.current && handleDevicePermissionDialogOpen();
  }, []);

  return {
    videoElemRef,
    showTime,
    isLive,
    isStreamLoading,
    isDevicePermissionsDialogOpen,
    isEndShowDialogOpen,
    isEndShowSucessDialogOpen,
    isConnectionErrorDialogOpen,
    isDeviceLoading,
    manageable,
    status,
    errorAlertMessage,
    selectedVideoDevice,
    handleError,
    handleStopStream,
    handleDevicePermissionDialogClose,
    handleEndShowDialogClose,
    handleEndShowSucessDialogClose,
    handleConnectionErrorDialogOpen,
    handleConnectionErrorDialogClose,
    handleStartStream,
    handlePermissions,
    handleSwitchCamera,
  };
};
