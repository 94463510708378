import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-input': {
    lineHeight: '1.375rem',
    color: 'text.primary',
    fontWeight: 400,
    fontSize: '1rem',
    padding: '8px 12px',
    '&::placeholder': {
      fontSize: '14px',
      color: theme.palette.grey[800],
    },
  },
}));
