import * as React from 'react';
import { useMediaQuery } from '@mui/material';

interface DeviceMetadataContextData {
  readonly isMobileView: boolean;
}

const DeviceMetadataContext = React.createContext<DeviceMetadataContextData>({} as DeviceMetadataContextData);

export const DeviceMetadataProvider = ({ children }: { children: React.ReactNode }) => {
  const isMobileView = useMediaQuery('(max-width:800px)');

  const value: DeviceMetadataContextData = {
    isMobileView,
  };

  return <DeviceMetadataContext.Provider value={value}>{children}</DeviceMetadataContext.Provider>;
};

export const useDeviceMetadata = (): DeviceMetadataContextData => React.useContext(DeviceMetadataContext);
