import { Box, Typography } from '@mui/material';

export const CustomNoRowsOverlay = () => {
  //TRANSLATION
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Typography fontWeight={700} fontSize={16} sx={{ mt: 1 }}>
        There are no shows at the moment.
      </Typography>
    </Box>
  );
};
