import { Icon, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { RecoverPwdStage } from '../../pages/Auth/ForgetPassworPage';
import { StyledButton } from '../UI/StyledButton';

export const RecoverPwdSucessWrapper = (props: { setRecoverPwdStage: (stage: RecoverPwdStage) => void }) => {
  const navigate = useNavigate();
  const redirectHandler = () => {
    props.setRecoverPwdStage(RecoverPwdStage.RequestRecoverPwd);
    navigate('/');
  };

  return (
    <Stack justifyContent="center" alignItems="center" spacing={4.375} my={6.25}>
      <Box component="img" src="/LSLogo.svg" alt="LS Logo" width={200} height={20} />

      <Box>
        <Stack justifyContent="center" alignItems="center" spacing={3} width={252}>
          <Stack direction="row" spacing={1}>
            <Icon className="fa-light fa-envelope" />
            <Typography fontSize={14}>
              Please, check your email. If you are registered in the system, you will receive a recovery link.{' '}
            </Typography>
          </Stack>

          <StyledButton
            onClick={redirectHandler}
            variant="contained"
            color="secondary"
            aria-label="Login"
            disableElevation
            type="submit"
          >
            Back to login
          </StyledButton>
        </Stack>
      </Box>
    </Stack>
  );
};
