import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from './components/Authentication/AuthProvider';
import ProtectedRoutingContainer from './components/Layout/ProtectedRoutingContainer';
import PublicRoutingContainer from './components/Layout/PublicRoutingContainer';
import BroadcastPage from './pages/Show/BroadcastPage';
import LoginPage from './pages/Auth/LoginPage';
import ShowDetailPage from './pages/Show/ShowDetailPage';
import ShowListPage from './pages/Show/ShowListPage';
import ForgetPassworPage from './pages/Auth/ForgetPassworPage';

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/shows" /> : <Navigate to="/login" />} />

      <Route path="login/*" element={<PublicRoutingContainer />}>
        <Route index element={<LoginPage />} />
        <Route path="forget-password" element={<ForgetPassworPage />} />
      </Route>

      <Route path="shows/*" element={<ProtectedRoutingContainer />}>
        <Route index element={<ShowListPage />} />
        <Route path=":id" element={<ShowDetailPage />} />
        <Route path=":id/broadcast" element={<BroadcastPage />} />
      </Route>

      <Route path="*" element={isAuthenticated ? <Navigate to="/shows" /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
