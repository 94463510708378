import { Stack } from '@mui/material';
import { ControlSection } from '../../components/Broadcast/ControlSection';
import { ConnectionErrorDialog } from '../../components/Broadcast/Dialogs/ConnectionErrorDialog';
import { DevicePermissionAccessDialog } from '../../components/Broadcast/Dialogs/DevicePermissionAccessDialog';
import { EndShowDialog } from '../../components/Broadcast/Dialogs/EndShowDialog';
import { EndShowSucessDialog } from '../../components/Broadcast/Dialogs/EndShowSucessDialog';
import { InfoSection } from '../../components/Broadcast/InfoSection';
import { StreamPreview } from '../../components/Broadcast/StreamPreview';
import { ViewFinderIcon } from '../../components/Broadcast/ViewFinderIcon';
import { BroadcastLoadingSpinner } from '../../components/UI/Indicators/BroadcastLoadingSpinner';
import { ErrorAlert } from '../../components/Broadcast/ErrorAlert';
//import { ZoomIndicator } from '../../components/Broadcast/Dialogs/ZoomIndicator';
import { useBroadcastSDK } from '../../components/Broadcast/useBroadcastSDK';
//import { useCameraZoom } from '../../components/Broadcast/Dialogs/ZoomIndicator/useCameraZoom';

declare global {
  interface MediaTrackConstraintSet {
    zoom?: ConstrainDouble;
  }

  interface MediaTrackCapabilities {
    zoom: ConstrainDouble;
  }
}

function BroadcastPage() {
  const {
    isLive,
    isStreamLoading,
    isDevicePermissionsDialogOpen,
    isEndShowDialogOpen,
    isDeviceLoading,
    isConnectionErrorDialogOpen,
    isEndShowSucessDialogOpen,
    videoElemRef,
    showTime,
    manageable,
    status,
    errorAlertMessage,
    //selectedVideoDevice,
    handleError,
    handleSwitchCamera,
    handleStopStream,
    handleConnectionErrorDialogClose,
    handleDevicePermissionDialogClose,
    handleEndShowSucessDialogClose,
    handleEndShowDialogClose,
    handleStartStream,
    handlePermissions,
  } = useBroadcastSDK();

  // const { handleZoomLevel, isZoomSupported, maxZoomLevel, minZoomLevel } = useCameraZoom({
  //   deviceId: selectedVideoDevice.current?.device?.deviceId,
  //   handleError,
  // });

  return (
    <Stack height="100%" direction="column" alignItems="center" overflow="hidden">
      <StreamPreview videoElemRef={videoElemRef} />

      {!isEndShowDialogOpen && !isEndShowSucessDialogOpen && (
        <InfoSection isLive={isLive} isStreamLoading={isStreamLoading} handleSwitchCamera={handleSwitchCamera} />
      )}
      {/* {isZoomSupported && (
        <ZoomIndicator handleZoomLevel={handleZoomLevel} maxZoomLevel={maxZoomLevel} minZoomLevel={minZoomLevel} />
      )} */}
      {!isEndShowDialogOpen && !isEndShowSucessDialogOpen && (
        <ControlSection
          handleStartStream={handleStartStream}
          showTime={showTime}
          isLive={isLive}
          isLoading={isStreamLoading || isDeviceLoading}
          manageable={!manageable}
          status={status}
        />
      )}
      {isDeviceLoading && <BroadcastLoadingSpinner />}
      {!isLive && !isDeviceLoading && <ViewFinderIcon />}
      <DevicePermissionAccessDialog
        open={isDevicePermissionsDialogOpen}
        handleClose={handleDevicePermissionDialogClose}
        handlePermissions={handlePermissions}
      />
      <EndShowDialog
        open={isEndShowDialogOpen}
        handleStopStream={handleStopStream}
        handleClose={handleEndShowDialogClose}
      />
      <ConnectionErrorDialog open={isConnectionErrorDialogOpen} handleClose={handleConnectionErrorDialogClose} />
      <EndShowSucessDialog open={isEndShowSucessDialogOpen} handleClose={handleEndShowSucessDialogClose} />
      <ErrorAlert errorAlertMessage={errorAlertMessage} handleError={handleError} />
    </Stack>
  );
}

export default BroadcastPage;
