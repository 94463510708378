import { Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { StyledButton } from '../../UI/StyledButton';
import { useNavigate } from 'react-router-dom';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
}

export const EndShowSucessDialog = (props: DialogProps) => {
  const { open, handleClose } = props;
  const navigate = useNavigate();
  const handleRedirectionToDashboard = () => navigate('/shows');

  const handleClick = () => {
    handleRedirectionToDashboard();
    handleClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', padding: '20px', width: '100%', margin: '28px' } }}
      fullWidth
      open={open}
      aria-labelledby="show-ended-dialog-title"
      aria-describedby="show-ended-dialog-description"
    >
      <DialogTitle id="show-ended-dialog-title" sx={{ textAlign: 'center' }}>
        <Typography component="span" fontSize={20} fontWeight={700} color="primary.main">
          Show successfully ended
        </Typography>
      </DialogTitle>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <StyledButton variant="contained" color="secondary" onClick={handleClick} sx={{ fontSize: '16px' }}>
          Go to Dashboard
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
