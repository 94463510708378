import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { StyledButton } from '../../UI/StyledButton';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handlePermissions: () => void;
}

export const DevicePermissionAccessDialog = (props: DialogProps) => {
  const { open, handleClose, handlePermissions } = props;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', padding: '20px', width: '100%', margin: '16.5px' } }}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="device-permission-access-dialog-title"
      aria-describedby="device-permission-access-dialog-description"
    >
      <DialogTitle id="device-permission-access-dialog-title" sx={{ p: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" fontSize={20} fontWeight={700} color="text.primary">
            Camera access
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <Icon className="fa-regular" baseClassName="fa-xmark-large" sx={{ color: 'text.primary' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ p: 0, my: '20px' }}>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Icon className="fa-light" baseClassName="fa-camera" sx={{ fontSize: '50px', color: 'secondary.dark' }} />
          <DialogContentText id="device-permission-access-dialog-description">
            <Typography component="span" fontSize={14} color="text.primary">
              Please grant us permission to access your camera for video streaming.
            </Typography>
          </DialogContentText>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack spacing={2} direction="column" justifyContent="center">
          <StyledButton variant="contained" color="secondary" onClick={handlePermissions} sx={{ fontSize: '14px' }}>
            Allow access
          </StyledButton>
          <StyledButton sx={{ fontSize: '14px' }} variant="outlined" color="secondary" onClick={handleClose}>
            Do not allow
          </StyledButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
