import * as React from 'react';
import { Alert, Snackbar } from '@mui/material';

export const ErrorAlert = (props: { errorAlertMessage: string; handleError: (errorMsg: string) => void }) => {
  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    props.handleError('');
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={props.errorAlertMessage !== ''}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%', mt: 5 }}>
        {props.errorAlertMessage}
      </Alert>
    </Snackbar>
  );
};
