import { AmazonIVSBroadcastClient } from 'amazon-ivs-web-broadcast';
import { useCallback } from 'react';

export const useMixer = () => {
  const getAudioStream = useCallback(async (deviceId: string) => {
    let media;

    const audioConstraints = {
      deviceId: { exact: deviceId },
    };

    try {
      media = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
      });
    } catch (e) {
      media = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
      });
    }
    return media;
  }, []);

  // Removes a device from the mixer
  const removeMixerDevice = useCallback(
    async (
      client: null | AmazonIVSBroadcastClient,
      mixerDevice: MediaDeviceInfo | null,
      handleError: (errMsg: string) => void,
    ) => {
      if (!mixerDevice) return;

      const { deviceId } = mixerDevice;
      const audioStream = client?.getAudioInputDevice(deviceId);
      try {
        if (audioStream) {
          client?.disableAudio();
          client?.removeAudioInputDevice(deviceId);
          return;
        }
      } catch (err) {
        handleError(err as string);
      }
    },
    [],
  );

  // Adds an audio device to the mixer
  const addMixerDevice = useCallback(
    async (
      client: null | AmazonIVSBroadcastClient,
      mixerDevice: MediaDeviceInfo,
      handleError: (errMsg: string) => void,
    ) => {
      try {
        await removeMixerDevice(client, mixerDevice, handleError);
        const { deviceId } = mixerDevice;
        const audioStream = await getAudioStream(deviceId);
        await client?.addAudioInputDevice(audioStream, deviceId);
      } catch (err) {
        handleError(err as string);
      }
    },
    [getAudioStream, removeMixerDevice],
  );

  return {
    addMixerDevice,
    removeMixerDevice,
    getAudioStream,
  };
};
