import { Paper, styled } from '@mui/material';

export const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  maxWidth: 420,
  maxHeight: 600,
  minWidth: 380,
  minHeight: 300,
  borderRadius: '16px',
  boxShadow: '0px 4px 24px rgba(211, 212, 213, 0.2)',
});
