import { Theme, createTheme } from '@mui/material';
import defaultStyle from './theme-default.json';

declare module '@mui/material/styles' {
  interface SquareButton {
    default?: string;
    hover?: string;
    size?: string;
    spacing?: string;
  }

  interface ShowStatus {
    background?: StatusList;
    text?: StatusList;
    height?: string;
    borderRadius?: string;
    padding?: string;
  }

  interface StatusList {
    live?: string;
    recorded?: string;
    upcoming?: string;
  }

  interface VideoBackgrounds {
    viewLayer?: string;
    controlPanel?: string;
    minimizedPanel?: string;
  }

  interface Video {
    backgrounds?: VideoBackgrounds;
    progressBar?: string;
    progressBarRail?: string;
  }

  interface MVCardActionArea {
    background: string;
    backgroundHover: string;
    backgroundHighlighted: string;
  }

  interface ProductItems {
    name?: string;
    brand?: string;
    MVCardActionArea?: MVCardActionArea;
  }

  interface DefaultMessage {
    background?: string;
    nameColor?: string;
    textColor?: string;
    linkColor?: string;
  }

  interface ExtendedModeratorMessage {
    background?: string;
    MVBackgroundHover?: string;
    nameColor?: string;
    textColor?: string;
    linkColor?: string;
  }

  interface ExtendedUserMessage {
    background?: string;
    MVBackgroundHover?: string;
    MVBackgroundMultiModerator?: string;
    nameColor?: string;
    MVNameErrorColor?: string;
    textColor?: string;
    MVTextErrorColor?: string;
    linkColor?: string;
  }

  interface RepliedMessageRespond {
    background?: string;
    textColor?: string;
    linkColor?: string;
    moderatorNameColor?: string;
  }

  interface RepliedMessageRespondDesktop extends RepliedMessageRespond {
    MVBackgroundHover?: string;
  }

  interface RepliedDesktopMessage {
    background?: string;
    textColor?: string;
    MVTextErrorColor?: string;
    linkColor: string;
    userNameColor?: string;
    MVUserErrorNameColor?: string;
    respond?: RepliedMessageRespondDesktop;
  }

  interface RepliedMobileMessage {
    borderColor?: string;
    textColor?: string;
    linkColor?: string;
    moderatorNameColor?: string;
    userNameColor?: string;
    respond?: RepliedMessageRespond;
  }

  interface PinnedMessage {
    background?: string;
    moderatorNameColor?: string;
    userNameColor?: string;
    textColor?: string;
    linkModeratorColor?: string;
    linkUserColor?: string;
  }

  interface ReplyingMessage {
    background?: string;
    replyToColor?: string;
    userNameColor?: string;
    textColor?: string;
  }

  interface ActionHint {
    background?: string;
    textColor?: string;
    buttonColor?: string;
    closeColor?: string;
  }

  interface DesktopChat {
    user?: ExtendedUserMessage;
    moderator?: ExtendedModeratorMessage;
    replied?: RepliedDesktopMessage;
    pinned?: PinnedMessage;
    MVReplying?: ReplyingMessage;
    MVActionHint?: ActionHint;
  }

  interface MobileChat {
    user?: DefaultMessage;
    moderator?: DefaultMessage;
    replied?: RepliedMobileMessage;
    pinned?: PinnedMessage;
  }

  interface ChatMessage {
    desktop?: DesktopChat;
    mobile?: MobileChat;
  }

  interface DefaultValues {
    color?: DefaultColors;
    borderRadius?: string;
  }

  interface DefaultColors {
    text?: string;
    active?: string;
    error?: string;
    success?: string;
  }

  interface ChatBoxButton {
    default?: string;
    active?: string;
  }

  interface ChatBoxInput {
    textColor?: string;
    placeholderColor?: string;
    borderColor?: string;
    borderColorActive?: string;
    padding?: string;
    backgroundColor: string;
    borderRadius?: string;
  }

  interface ChatBoxDefault {
    button?: ChatBoxButton;
    input?: ChatBoxInput;
    background?: string;
  }

  interface ChatBox {
    desktop?: ChatBoxDefault;
    mobile?: ChatBoxDefault;
  }

  interface ChatAccessDialogInputField {
    borderColorDefault: string;
    borderColorActive: string;
    borderColorHover: string;
    borderColorSuccess: string;
    borderColorError: string;
    borderRadius: string;
  }

  interface ChatAccessDialogButton {
    backgroundColorDefault: string;
    backgroundColorHover: string;
    backgroundColorActive: string;
    fontColorDefault: string;
    fontColorHover: string;
    fontColorActive: string;
  }

  interface ChatAccessDialog {
    button: ChatAccessDialogButton;
    headlineColor: string;
    inputField: ChatAccessDialogInputField;
    termsLinkColor: string;
    checkboxColor: string;
  }

  interface HighlightedItems {
    name: string;
    brand: string;
    cardBorderRadius: string;
    imageBorderRadius: string;
    cardPadding: string;
  }

  interface CTAButton {
    backgroundColor: string;
    textColor: string;
  }

  interface PWA {
    infoSection?: {
      backgroundColor?: string;
      button?: {
        backgroundColor?: string;
      };
    };
  }

  interface Components {
    logoURL?: string;
    logoSize?: string;
    logoPercentSize?: string;
    defaultValues?: DefaultValues;
    squareButton?: SquareButton;
    showStatus?: ShowStatus;
    video?: Video;
    productItems?: ProductItems;
    highlightedItems?: HighlightedItems;
    chatBoxTriggerBackground?: string;
    chatBox?: ChatBox;
    chatMessage?: ChatMessage;
    chatAccessDialog?: ChatAccessDialog;
    buttonCTA?: CTAButton;
    pwaComponents?: PWA;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    scrollbar: true;
  }
}

const theme: Theme = createTheme(defaultStyle.theme);

export default theme;
