export class TokenService {
  getLocalAccessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  updateLocalAccessToken(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  getLocalRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  updateRefreshToken(token: string): void {
    localStorage.setItem('refreshToken', token);
  }

  getLocalIdToken(): string | null {
    return localStorage.getItem('idToken');
  }

  updateLocalIdToken(token: string): void {
    localStorage.setItem('idToken', token);
  }
}

const tokenService = new TokenService();

export default tokenService;
