import { Icon, InputAdornment, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ChangeEvent, useCallback, useState } from 'react';
import { useLoginTranslation } from '../../i18n/useTranslations';
import { RecoverPwdStage } from '../../pages/Auth/ForgetPassworPage';
import { useRecoverPwd } from '../Authentication/methods';
import { StyledButton } from '../UI/StyledButton';
import { StyledTextField } from '../UI/StyledTextField';

export const RecoverPwdRequestWrapper = (props: { setRecoverPwdStage: (stage: RecoverPwdStage) => void }) => {
  const { t } = useLoginTranslation();
  const { handleRequestRecoverPwd } = useRecoverPwd(props.setRecoverPwdStage);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<boolean>(false);

  const isInValidEmail = useCallback((email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setError(false);
    } else {
      setError(true);
    }
  }, []);

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    isInValidEmail(event.target.value);
  };

  const submitRequest = async () => await handleRequestRecoverPwd(email);

  return (
    <Stack justifyContent="center" alignItems="center" spacing={4.375} my={6.25}>
      <Box component="img" src="/LSLogo.svg" alt="LS Logo" width={200} height={20} />
      <Box>
        <Typography
          sx={{
            fontWeight: 900,
            color: 'var(--primary-color)',
          }}
          variant="h6"
        >
          Recover your password
        </Typography>
      </Box>

      <Box>
        <Stack justifyContent="center" alignItems="center" spacing={2} width={252}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={1}>
              <Icon className="fa-light fa-envelope" />
              <Typography fontSize={14}>
                Please, enter your email. We will send you a link to reset your password.
              </Typography>
            </Stack>
            <StyledTextField
              sx={{
                input: { color: error ? 'error.main' : 'inherit' },
                width: '100%',
                padding: 0,
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: error ? 'error.main' : 'primary.main',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: error ? 'error.main' : 'primary.main',
                },
              }}
              variant="outlined"
              placeholder={t('userFieldPlaceholder')}
              onChange={onEmailChange}
              error={error}
              value={email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color={error ? 'error' : 'inherit'} fontSize="small" className="fa-light fa-user" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <StyledButton
            onClick={submitRequest}
            variant="contained"
            color="secondary"
            aria-label="Login"
            disableElevation
            disabled={error || email === ''}
            type="submit"
          >
            Send
          </StyledButton>
        </Stack>
      </Box>
    </Stack>
  );
};
