import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const ONE_MINUTE = 60000;

export const getTimeDifference = (showTime: string | null) => {
  const timeDifference = dayjs(showTime).diff(dayjs(), 'minute');
  return timeDifference >= 0 ? timeDifference : 0;
};

export const useCountDown = (showTime: string | null, isLive: boolean) => {
  const [isTimeUp, setIsTimeUp] = useState<boolean>(false);
  const [timeDiff, setTimeDiff] = useState<number | null>(null);

  useEffect(() => {
    const timeDifference = getTimeDifference(showTime);
    setTimeDiff(timeDifference);
  }, [showTime]);

  useEffect(() => {
    let countDown: NodeJS.Timeout;

    const startCountDown = () => {
      countDown = setInterval(() => {
        const updatedTimeDifference = dayjs(showTime).diff(dayjs(), 'minute');
        setTimeDiff(updatedTimeDifference);
        if (updatedTimeDifference === 0) {
          setIsTimeUp(true);
        }
      }, ONE_MINUTE);
    };

    const stopCountDown = () => clearInterval(countDown);

    if (timeDiff && timeDiff <= 0) {
      setIsTimeUp(true);
    } else if (timeDiff && timeDiff > 0) {
      startCountDown();
    } else {
      stopCountDown();
    }

    return () => stopCountDown();
  }, [showTime, isLive, timeDiff]);

  return { isTimeUp, timeDiff };
};
