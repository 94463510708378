import { Icon } from '@mui/material';

export const ViewFinderIcon = () => (
  <Icon
    className="fa-light fa-expand"
    sx={{
      fontSize: '120px',
      color: 'common.white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  />
);
