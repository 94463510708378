import { Box, Chip, useTheme } from '@mui/material';

export const VideoLabelChip = () => {
  const theme = useTheme();

  return (
    <Box sx={{ pl: 1 }}>
      <Chip
        label={
          <span>
            <span style={{ fontSize: 'larger' }}></span>
            <>&#8226; Live</>
          </span>
        }
        sx={{
          color: theme.components?.showStatus?.text?.live,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          background: theme.components?.showStatus?.background?.live,
          borderColor: 'none',
          borderRadius: 1,
          mt: 1.25,
        }}
      />
    </Box>
  );
};
