import { Stack } from '@mui/material';
import { StyledPaper } from '../../components/UI/StyledPaper';
import { useState } from 'react';
import { RecoverPwdRequestWrapper } from '../../components/RecoverPwd/RecoverPwdRequestWrapper';
import { RecoverPwdSucessWrapper } from '../../components/RecoverPwd/RecoverPwdSucessWrapper';

export enum RecoverPwdStage {
  RequestRecoverPwd = 'RequestRecoverPwd',
  RecoverPwdSuccess = 'RecoverPwdSuccess',
}

export default function ForgetPassworPage() {
  const [recoverPwdStage, setRecoverPwdStage] = useState<RecoverPwdStage>(RecoverPwdStage.RequestRecoverPwd);

  return (
    <Stack flexGrow={1} direction="column" alignItems="center" justifyContent="center">
      <StyledPaper elevation={0}>
        <Stack justifyContent="center" alignItems="center" width={300}>
          {recoverPwdStage === RecoverPwdStage.RequestRecoverPwd ? (
            <RecoverPwdRequestWrapper setRecoverPwdStage={setRecoverPwdStage} />
          ) : (
            <RecoverPwdSucessWrapper setRecoverPwdStage={setRecoverPwdStage} />
          )}
        </Stack>
      </StyledPaper>
    </Stack>
  );
}
