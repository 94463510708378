import { LoginRequestParams as UserLoginForm } from '../../api/user/userApi.models';
import { Icon, InputAdornment } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { StyledTextField } from '../UI/StyledTextField';
import { useLoginTranslation } from '../../i18n/useTranslations';

export const LoginEmailController = (props: { control: Control<UserLoginForm>; error: boolean }) => {
  const { control, error } = props;
  const { t } = useLoginTranslation();

  return (
    <Controller
      name="email"
      rules={{ required: true }}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => (
        <StyledTextField
          sx={{
            input: { color: error ? 'error.main' : 'inherit' },
            width: '100%',
            padding: 0,
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: fieldState.error || error ? 'error.main' : 'primary.main',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: fieldState.error || error ? 'error.main' : 'primary.main',
            },
          }}
          variant="outlined"
          placeholder={t('userFieldPlaceholder')}
          onChange={onChange}
          error={!!fieldState.error}
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon color={error ? 'error' : 'inherit'} fontSize="small" className="fa-light fa-user" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
