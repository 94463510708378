import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import { StyledButton } from '../../UI/StyledButton';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleStopStream: () => void;
}

export const EndShowDialog = (props: DialogProps) => {
  const { open, handleClose, handleStopStream } = props;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', padding: '20px', width: '100%', margin: '28px' } }}
      fullWidth
      open={open}
      aria-labelledby="end-show-dialog-title"
      aria-describedby="end-show-dialog-description"
    >
      <DialogTitle id="end-show-dialog-title" sx={{ textAlign: 'center' }}>
        <Typography component="span" fontSize={18} fontWeight={700} color="text.primary">
          Are you sure you want to end the show?
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0, mb: '20px' }}>
        <DialogContentText id="end-show-dialog-description" sx={{ textAlign: 'center' }}>
          <Typography component="span" fontSize={16} color="text.primary">
            This action can not be undone.
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack spacing={3} direction="row" justifyContent="center">
          <StyledButton sx={{ fontSize: '16px' }} variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </StyledButton>
          <StyledButton variant="contained" color="secondary" onClick={handleStopStream} sx={{ fontSize: '16px' }}>
            End
          </StyledButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
