import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from '../../UI/StyledButton';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
}

export const ConnectionErrorDialog = (props: DialogProps) => {
  const { open, handleClose } = props;
  const navigate = useNavigate();
  const handleRedirectionToDashboard = () => navigate('/shows');

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', padding: '20px', width: '100%', margin: '42px' } }}
      fullWidth
      open={open}
      aria-labelledby="connection-error-dialog-title"
      aria-describedby="connection-error-dialog-description"
    >
      <DialogTitle
        id="connection-error-dialog-title"
        sx={{ px: 0, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}
      >
        <Icon className="fa-light fa-triangle-exclamation" />
        <Typography component="span" fontSize={16} fontWeight={700} color="text.primary">
          Check your connection.
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0, mb: '20px' }}>
        <DialogContentText id="connection-error-dialog-description" sx={{ textAlign: 'center' }}>
          <Typography component="span" fontSize={14} color="text.primary" width="220px">
            You don’t seem to have an active connection. Please, check your connection and try again.
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose();
            handleRedirectionToDashboard();
          }}
          sx={{ fontSize: '16px' }}
        >
          Go to Dashboard
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
