import { SyntheticEvent, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { StyledDataGrid } from '../../components/ShowList/ShowTable/StyledDataGrid';
import { Show } from '../../api/show/showApi.models';
import { Layout } from '../../components/Layout/Layout';
import { TabOptions, TabSelection } from '../../components/UI/StyledTab';
import { LoadingSpinner } from '../../components/UI/Indicators/LoadingSpinner';
import { useShowTable } from './useShowTable';
import { useShowsTranslation } from '../../i18n/useTranslations';

const DEFAULT_TAB_VALUE = 'All';

const TAB_OPTIONS: TabOptions[] = [
  { label: 'All', value: 'All' },
  { label: 'Live', value: 'Live' },
  { label: 'Upcoming', value: 'Upcoming' },
];

//TRANSLATION
function ShowListPage() {
  const { columns, rows, isLoading, onClickHandler } = useShowTable();
  const { t } = useShowsTranslation();
  const [tabValue, setTabValue] = useState(DEFAULT_TAB_VALUE);
  const [filteredRows, setFilterRows] = useState(rows);

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    if (newValue !== DEFAULT_TAB_VALUE) {
      setFilterRows(rows.filter((row: Show) => row.displayStatus === newValue));
    } else {
      setFilterRows(rows);
    }
    setTabValue(newValue);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <Layout>
        <Stack direction="column">
          <Typography color="primary.main" textAlign="center" fontWeight={700} fontSize={22} my={3.75}>
            {t('welcome')}
          </Typography>
          <Stack
            sx={{ backgroundColor: 'common.white', borderRadius: '16px' }}
            direction="column"
            alignItems="center"
            pt={3.75}
          >
            <Typography color="text.primary" textAlign="center" fontWeight={700} fontSize={22}>
              Shows
            </Typography>
            <TabSelection
              options={TAB_OPTIONS}
              disabled={false}
              selectedTabValue={tabValue}
              onValueChange={handleChange}
            />
            <StyledDataGrid
              cellClickHandler={onClickHandler}
              columns={columns}
              rows={filteredRows}
              loading={isLoading}
            />
          </Stack>
        </Stack>
      </Layout>
    );
  }
}

export default ShowListPage;
