import { getExpDate, parseJwtToken } from '../../utils/parseJwtToken';
import tokenService from '../../services/token.service';
import dayjs from 'dayjs';

export const getUserLoggedInStatus = (): boolean => {
  const accessToken = tokenService.getLocalAccessToken();
  const idToken = tokenService.getLocalIdToken();

  if (!accessToken || !idToken) return false;

  const accessJwtObj = parseJwtToken(accessToken);
  const expDate = dayjs(getExpDate(accessJwtObj.exp));
  const now = dayjs();

  if (expDate > now) {
    return true;
  } else {
    return false;
  }
};
