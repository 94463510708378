import { MutableRefObject } from 'react';

interface GetDevices {
  handleError: (message: string) => void;
  deviceRef: MutableRefObject<MediaDeviceInfo[] | null>;
}

export const getVideoDevices = async ({ handleError, deviceRef }: GetDevices) => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => device.kind === 'videoinput');
    if (!videoDevices.length) {
      handleError('No video devices found.');
    }
    deviceRef.current = videoDevices;
    return videoDevices;
  } catch (err) {
    handleError("Can't get video devices.");
  }
};

export const getAudioDevices = async ({ handleError, deviceRef }: GetDevices) => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter((device) => device.kind === 'audioinput');
    if (!audioDevices.length) {
      handleError('No audio devices found.');
    }
    deviceRef.current = audioDevices;
    return audioDevices;
  } catch (err) {
    handleError("Can't get audio devices.");
  }
};
